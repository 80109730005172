<template>
    <div class="page">
        <div class="container padded">
            <PageHeader :title="$t('title_people_and_teams')"></PageHeader>

            <Tabs :links="isFeaturePreviewActive ? tabsWithFeaturePreviews : tabs"/>

            <transition
                name="fade-fast"
                mode="out-in"
            >
                <keep-alive>
                    <router-view/>
                </keep-alive>
            </transition>
        </div>
    </div>
</template>
<script>
import PageHeader from "@web/components/PageHeader.vue";
import Tabs from "@web/components/Tabs.vue";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { FEATURE_PREVIEW_ACTIVE } from "@web/store/intranet/getters";

export default {
    name: "People",
    components: { PageHeader, Tabs },
    data() {
        return {
            tabs: [
                { to: "people", title: this.$t("people") },
                { to: "teams", title: this.$t("teams") },
            ],
            tabsWithFeaturePreviews: [
                { to: "network", title: this.$t("your_network") },
                { to: "people", title: this.$t("people") },
                { to: "teams", title: this.$t("teams") },
                { to: "groups", title: this.$t("groups") },
                { to: "skillfinder", title: this.$t("skillfinder") },
            ],
        };
    },
    computed: {
        ...mapGetters({ isFeaturePreviewActive: INTRANET_MODULE_NAME + FEATURE_PREVIEW_ACTIVE }),
    },
    mounted() {
        window.scrollTo(0, 0);
    },
};
</script>

<style lang="scss" scoped>
.grid {
    margin-bottom: 2rem;

    h3 {
        margin: 0;
    }
}
</style>
